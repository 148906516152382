import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map, Observable, retry } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

import { StorageUtils } from '../utils/storage-utils';
import { ConfigService } from './config-service';
import { WebStorageService } from './webstorage-service';

@Injectable({
	providedIn: 'root',
  })
export class UserService {
	langs: Array<string> = ['en_EN', 'pt_PT'];
	defaultlang  = 'en_EN';
	logo = '/images/logo.png';
	avatar = '/assets/images/avatar/avatar.gif';
	pages: Array<{title: string; page: string; class: string}> = [];
	headers = new HttpHeaders();
    constructor(
		public http: HttpClient,
		public configService: ConfigService,
		public localStorageService: WebStorageService,
		public jwtHttp: JwtHelperService
	) {
		this.avatar = this.configService.getServerurl() + '/assets/images/avatar/avatar.gif';
		this.logo = this.configService.getServerurl() + '/images/logo.png';
		const user = this.getUser();
		if ('1' == user.avatar) {
			this.avatar = this.configService.getServerurl() + '/images/avatar/' + user.id + '.jpg?' + (new Date()).getTime();
		} else {
			this.avatar = this.configService.getServerurl() + '/assets/images/avatar/avatar.gif';
		}
		this.pages.push({title: 'VIP list', page: 'VipListPage', class: 'VipListPage'});
		this.pages.push({title: 'Pre-defined comments', page: 'UserCommentlistPage', class: 'UserCommentlistPage'});
		this.pages.push({title: 'Absences', page: 'UserAbsencelistPage', class: 'UserAbsencelistPage'});
	}

	public refreshAvatar(): void {
		//console.log('refreshAvatar');
		const user = this.getUser();
		if ('1' == user.avatar) {
			this.avatar = this.configService.getServerurl() + '/images/avatar/' + user.id + '.jpg?' + (new Date()).getTime();
		} else {
			this.avatar = this.configService.getServerurl() + '/assets/images/avatar/avatar.gif';
		}
	}

	public refreshLogo(): void {
		//console.log('refreshLogo');
		this.logo = this.configService.getServerurl() + '/images/logo.png?' + (new Date()).getTime();
	}


	public validateLang(lang: string): string {
		if (-1 == this.langs.indexOf(lang))  {
			lang = this.defaultlang;
		}

		return lang;
	}

	public login(credentials: any) {
		let server_url = this.configService.getVar('server_url');
		if (credentials.server_url) {
			server_url = credentials.server_url;
		}
		//console.log('Login');
		credentials = Object.assign(credentials, {platform_cordova: this.localStorageService.get('platform_cordova')});
		//this.headers.set('Access-Control-Allow-Origin', '*');
		//this.headers.set('Access-Control-Allow-HttpHeaders', 'Origin, X-Requested-With, Content-Type, Accept');authExempt
		//console.log(this.configService.getVar('server_url')  + '/user/login');
		//console.log(credentials);
		return this.http.post(this.configService.getVar('server_url')  + '/user/login', credentials)
				.pipe(retry(this.configService.getHttp().retry));
			//.subscribe(retry(this.configService.getHttp().retry));
	}

	public accountLogin(credentials: any) {
		let server_url = credentials.server_url;
		//this.headers.set('Access-Control-Allow-Origin', '*');
		//this.headers.set('Access-Control-Allow-HttpHeaders', 'Origin, X-Requested-With, Content-Type, Accept');
		credentials = Object.assign(credentials, {platform_cordova: this.localStorageService.get('platform_cordova')});
		delete credentials.server_url;
		return this.http.post(server_url  + '/api/user/login', credentials)
				.pipe(retry(this.configService.getHttp().retry));
			//.subscribe(retry(this.configService.getHttp().retry));
	}

	public logout(): void {
		StorageUtils.removeItem('access_token');
		this.localStorageService.remove('access_token');
		this.localStorageService.remove('refresh_token');
		this.localStorageService.remove('username');
		this.localStorageService.remove('name');
		this.localStorageService.remove('id');
		this.localStorageService.remove('link');

		const user = this.getUser();
		user.access_token = '';
		this.setUser(JSON.stringify(user));
	}

	public isAuthenticated(): boolean {
		const token = this.localStorageService.get('access_token');
		return !this.jwtHttp.isTokenExpired(token);
	}

	public setUser(user: any): any {
		user = JSON.parse(user);
		delete user['access_token'];
		delete user['refresh_token'];
		user = JSON.stringify(user);

		StorageUtils.setItem('user', user);

		return this.getUser();
	}

	public getUser(): any {
		let user = JSON.parse(StorageUtils.getItem('user'));
		if (user === null) {
			user = {};
		} else {
			if (-1 == this.langs.indexOf(user.lang))  {
				user.lang = this.defaultlang;
			}
		}

		return user;
	}

	public removeUser(): void {
		StorageUtils.removeItem('user');
	}

	public uservip(params: any): any {
		return this.httpRequest('uservip', params);
	}

	public saveuservip(params: any): any {
		return this.httpRequest('saveuservip', params);
	}

	public tag(params: any): any {
		return this.httpRequest('tag', params);
	}

	public savecomment(params: any): any {
		return this.httpRequest('savecomment', params);
	}

	public findcomments(params: any): any {
		return this.httpRequest('findcomments', params);
	}

	public removecomments(params: any): any {
		return this.httpRequest('removecomments', params);
	}

	public findabsences(params: any): any {
		return this.httpRequest('findabsences', params);
	}

	public removeabsences(params: any): any {
		return this.httpRequest('removeabsences', params);
	}

	public saveuser(params: any): any {
		return this.httpRequest('saveuser', params);
	}

	public i18n(params: any): any {
		return this.httpRequest('i18n', params);
	}

	public getsystemdocabout(params: any): any {
		return this.httpRequest('getsystemdocabout', params);
	}

	public getallusers(params: any): any {
		return this.httpRequest('getallusers', params);
	}

	private httpRequest(method: string, params: any): Observable<any> {
		return this.http
			.post(this.configService.getVar('server_url')  + '/user/' + method, params)
			.pipe(retry(this.configService.getHttp().retry));
	}

}
