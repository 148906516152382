import { Component, ViewChild, OnDestroy } from '@angular/core';
import { NavController, NavParams, AlertController, IonNav } from '@ionic/angular';
import { IonItemSliding, IonFab } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';
import { Subscription, timer } from 'rxjs';

import { DocumentService } from '../../services/document-service';
import { ActionService } from '../../services/action-service';
import { ConfigService } from '../../services/config-service';
import { UserService } from '../../services/user-service';
import { StorageUtils } from '../../utils/storage-utils';

import { Toast } from '../../controllers/message-controller';
import { ActionSheet } from '../../controllers/actionsheet-controller';
import { Loading } from '../../controllers/message-controller';
import { Events } from '../../services/event-service';
import { WebStorageService } from '../../services/webstorage-service';


@Component({
	selector: 'app-page-list-actions',
	templateUrl: 'list-actions.html',
	providers: [ActionService, DocumentService, Toast],
	styleUrls: ['list-actions.scss']
})

export class ListActionsPage implements OnDestroy {

	@ViewChild('fab') fab: IonFab;

	finding = false;
	hasActions = false;

	index: number;
	iconLoading = false;
	actionAccessedIndex: any;
	refresher: any;
	refresherIsOn = false;
	title = ''; //page title
	params:{};
	pushPage: {};
	items: any[]; //actions
	itemsIdsSelected: Array<any> = [];
	itemstypes: any[]; //action types
	groups: Array<any> = [];
	tipo: any;
	total: string; //total
	totalfilter: number; //total of filter
	str: string;
	str2: string;
	countItemsChecked = 0;
	refresh: any;
	executeConfirm: any;
	alert: any;
	forwardAlert: any;
	editEnable = false;
	alertMore: any;
	searchParams = {nsearch: 0,
					searchString: '',
					idaction: '',
					idgroup: '',
					showScheculed: false,
					showViponly: false,
					action_was_accessed: false,
					fdatainicio: false, //flag that tells server to searchby datainicio
					datainiciodesde: '',
					datainicioate:'',
					fdatalimite: false, //flag that tells server to searchby datalimite
					datalimitedesde: '',
					datalimiteate:'',
					priorityflag: '',
					filter: {},
					offset: 0,
					limit: 20}; //search criteria
	datainiciodesdemin: string;
	datainiciodesdemax: string;
	datainicioatemin: string;
	datainicioatemax: string;
	datalimitedesdemin: string;
	datalimitedesdemax: string;
	datalimiteatemin: string;
	datalimiteatemax: string;

	priorityflags: Array<any> = [];
	showGroupSelectSearch = false;

	fShowHideAdvancedSearch = false;
	searchActionsByInput = false;

	actionServiceSubscription: Subscription;

	constructor(
				public navCtrl: NavController,
				public navParams: NavParams,
				public alertCtrl: AlertController,
				public events: Events,
				public localStorageService: WebStorageService,
				public translateService: TranslateService,
				public actionService: ActionService,
				public documentService: DocumentService,
				public configService: ConfigService,
				public userService: UserService,
				public actionSheet: ActionSheet,
				public toast: Toast,
				public loading: Loading,
				public nav: IonNav
			) {
		this.translateService.get('Actions').subscribe(
			value => {
				this.title = value;
			}
		);

		events.subscribe('functionCall:ListActionsPage',
			data => {
				if (undefined !== data.function) {
					switch (data.function) {
						case 'associatewitheachother':
							this.associate();
							break;
						default:
							break;
					}

				}
			}
		);


		StorageUtils.setItem('tab_selected', 'actions');
		this.localStorageService.set('tab_selected', 'actions');

		//console.log('params', this.navParams.get('params'));
		//console.log('configService', this.configService.getAllVar());

		if (this.navParams.get('params')) {

			const params = this.navParams.get('params');

			if ('undefined' != typeof params.filter) {
				this.searchParams.filter = params.filter;
				this.title = params.filter.name;

				//console.log('filter', params.filter);

				if (params.filter.idactionlist == -2 || params.filter.name == 'Group actions') {
					this.showGroupSelectSearch = true;
				} else {
					this.showGroupSelectSearch = false;
				}



			} else {

				let list_actions_vars = this.configService.getVar('list_actions');

				if (typeof list_actions_vars != 'undefined') {

					if (list_actions_vars.actions) {
						this.items = list_actions_vars.actions;
						this.hasActions = true;
						////console.log('hasActions', this.hasActions);
						////console.log('items = vars' , this.items);
					}
				}
			}
		}

		this.searchParams.datainiciodesde = (new Date()).toISOString().slice(0, 10);
		this.searchParams.datainicioate = (new Date()).toISOString().slice(0, 10);

		this.datainiciodesdemin = (new Date(new Date().setFullYear(new Date().getFullYear() - 10))).toISOString().slice(0, 10);
		this.datainiciodesdemax = (new Date(new Date().setFullYear(new Date().getFullYear() + 10))).toISOString().slice(0, 10);

		this.datainicioatemin = (new Date(new Date().setFullYear(new Date().getFullYear() - 10))).toISOString().slice(0, 10);
		this.datainicioatemax = (new Date(new Date().setFullYear(new Date().getFullYear() + 10))).toISOString().slice(0, 10);

		this.searchParams.datalimitedesde = (new Date()).toISOString().slice(0, 10);
		this.searchParams.datalimiteate = (new Date()).toISOString().slice(0, 10);

		this.datalimitedesdemin = (new Date(new Date().setFullYear(new Date().getFullYear() - 10))).toISOString().slice(0, 10);
		this.datalimitedesdemax = (new Date(new Date().setFullYear(new Date().getFullYear() + 10))).toISOString().slice(0, 10);

		this.datalimiteatemin = (new Date(new Date().setFullYear(new Date().getFullYear() - 10))).toISOString().slice(0, 10);
		this.datalimiteatemax = (new Date(new Date().setFullYear(new Date().getFullYear() + 10))).toISOString().slice(0, 10);
	}



	ionViewWillEnter() {

		this.find();

	}

	ionViewWillLeave() {
		this.editEnable = false;
		this.fab.close();

		if (this.loading.isOn) {
			this.loading.dismiss();
		}

	}

	ionViewDidEnter() {
	}

	ionViewDidLeave() {
		this.countItemsChecked = 0;
		this.iconLoading = false;
	}

	enableStartDate(){
		//this.searchActionsByStartDate = true
		this.searchParams.fdatainicio = true;
		//this.searchParams.fdatalimite = false;
	}

	enableLimitDate(){
		//this.searchActionsByLimitDate = true
		this.searchParams.fdatalimite = true;
		//this.searchParams.fdatainicio = false;
	}

	datainiciodesdeOnChange(){
		// this.searchActionsByStartDateSince = true;
		// this.searchActionsByStartDateUpTo = true;

		this.searchParams.fdatainicio = true;

		this.datainicioatemin = this.searchParams.datainiciodesde;

	}

	datainicioateOnChange(){
		// this.searchActionsByStartDateSince = true;
		// this.searchActionsByStartDateUpTo = true;

		this.searchParams.fdatainicio = true;

		this.datainiciodesdemax = this.searchParams.datainicioate;
	}

	datalimitedesdeOnChange(){
		// this.searchActionsByLimitDateSince = true;
		// this.searchActionsByLimitDateUpTo = true;

		this.searchParams.fdatalimite = true;
		this.datalimiteatemin = this.searchParams.datalimitedesde;

	}

	datalimiteateOnChange(){
		// this.searchActionsByLimitDateSince = true;
		// this.searchActionsByLimitDateUpTo = true;
		this.searchParams.fdatalimite = true;
		this.datalimitedesdemax = this.searchParams.datalimiteate;
	}

	cancelStartDate(){
		// this.searchActionsByStartDate = false;
		this.searchParams.fdatainicio = false;
	}

	cancelLimitDate(){
		// this.searchActionsByLimitDate = false;
		this.searchParams.fdatalimite = false;
	}



	processResults(data: any) {

		this.total = data.total;
		this.totalfilter = data.totalfilter;

		for (const a of data.list) {
			this.items.push(a);
		}

		if (this.items.length > 0) {
			this.hasActions = true;
		} else {
			this.hasActions = false;
		}

		for (let i = 0; i < this.items.length; i++) {
			if(!this.items[i].checked){
				this.items[i] = Object.assign(this.items[i], {checked:false});
			}

		}

		for (const a of data.listtype) {
			this.itemstypes.push(a);
		}

		for (const a of data.groups) {
			this.groups.push(a);
		}

		this.priorityflags = data.priorityflags;

		if (this.hasActions) {
			const actions = [];
			for (let i = 0; i < this.items.length; i++) {
				if (i < 20) {
					actions.push(this.items[i]);
				} else {
					break;
				}
			}

			if (this.configService.getVar('list_actions')) {
				let list_documents_vars = this.configService.getVar('list_actions');
				list_documents_vars = Object.assign(list_documents_vars, {'actions': actions});
				this.configService.setVar('list_actions', list_documents_vars);

			} else {
				this.configService.setVar('list_actions', {'actions': actions});
			}
		} else {
			if (this.configService.getVar('list_actions')) {
				let list_documents_vars = this.configService.getVar('list_actions');
				list_documents_vars = Object.assign(list_documents_vars, {'actions': []});
				this.configService.setVar('list_actions', list_documents_vars);

			} else {
				this.configService.setVar('list_actions', {'actions': []});
			}
		}
	}

	public find(): void {

		const datainiciodesdebackup = this.searchParams.datainiciodesde;
		const datainicioatebackup = this.searchParams.datainicioate;

		const datalimitedesdebackup = this.searchParams.datalimitedesde;
		const datalimiteatebackup = this.searchParams.datalimiteate;


		this.searchParams.nsearch = this.searchParams.nsearch + 1;
		if (this.searchParams.nsearch > 100) {
			this.searchParams.nsearch = 1;
		}

		if (!this.refresherIsOn){
			this.finding = true;
		}

		this.actionServiceSubscription = this.actionService.find(this.searchParams).subscribe({
			next: (data: any) => {
				if (this.searchParams.nsearch == data.nsearch) {

					if (this.refresherIsOn) {
						this.refresher.complete();
						this.refresherIsOn = false;
					} else {
						this.finding = false;
					}

					this.items = [];
					this.itemstypes = [];
					this.groups = [];
					this.itemsIdsSelected = [];

					this.processResults(data);
					if (this.searchParams.limit > this.totalfilter) {
						this.searchParams.limit = this.totalfilter;
					}
					//on complete

					this.searchActionsByInput = false;
					this.fShowHideAdvancedSearch = false;
				}
			},
			error: err => {

				if (this.refresherIsOn) {
					this.refresher.complete();
					this.refresherIsOn = false;
				} else {
					this.finding = false;
				}
				this.searchActionsByInput = false;


			},
			complete: () => {
			}
		});

		this.searchParams.datainiciodesde = datainiciodesdebackup;
		this.searchParams.datainicioate = datainicioatebackup;

		this.searchParams.datalimitedesde = datalimitedesdebackup;
		this.searchParams.datalimiteate = datalimiteatebackup;
	}

	getItemsDelayTimer: any = null;
	getItemsDelay(ev: any) {
		// set val to the value of the searchbar
		this.searchParams.searchString = '';
		if (ev.target.value) {
			this.searchParams.searchString = ev.target.value.trim();
		}

		/*let $searchParams = this.searchParams;
		let $this = this;

		if (this.getItemsDelayTimer) {
			clearTimeout(this.getItemsDelayTimer);
		}

		if (0 == $searchParams.searchString.length) {
			$this.find();
		} else {
			this.getItemsDelayTimer = setTimeout(function() {
				if ($searchParams.searchString.length > 2) {
					$this.find();
				}
			}, 1000); // Will do the ajax stuff after x ms
		}*/
	}

	getItems(ev: any): void {

		this.searchActionsByInput = true;
		this.getItemsDelay(ev);
		/*
		// set val to the value of the searchbar
		this.searchParams.searchString = '';
		if (ev.target.value) {
			this.searchParams.searchString = ev.target.value.trim();
		}

		if (0 == this.searchParams.searchString.length || this.searchParams.searchString.length > 2) {
			this.find();
		}
		*/
	}

	unread(item, slidingItem: IonItemSliding): void {
		slidingItem.close();

		if (false != item.action_was_accessed) {
			item.action_was_accessed = false;

			this.actionService.markunread(item).subscribe({
				next: data => {
				},
				error: err => {
				},
				complete: () => {
				}
			});
		}
	}

//	more(item: any, index: number, slidingItem: ItemSliding): void {
//
//		let translation = {cancel: '', ok: ''};
//		this.translateService.get('Cancel').subscribe(
//			value => {
//				translation.cancel = value;
//			}
//		)
//		this.translateService.get('ok').subscribe(
//			value => {
//				translation.ok = value;
//			}
//		)
//
//		this.translateService.get('More').subscribe(
//			value => {
//				this.alertTranslations.alertMoreTitle = value;
//			}
//		)
//		this.translateService.get('Forward').subscribe(
//			value => {
//				this.alertTranslations.forward = value;
//			}
//		)
//		this.translateService.get('Forward with pending action').subscribe(
//			value => {
//				this.alertTranslations.forwardwithaction = value;
//			}
//		)
//		this.translateService.get('Tag').subscribe(
//			value => {
//				this.alertTranslations.mark = value;
//			}
//		)
//		this.translateService.get('Tag as unread').subscribe(
//			value => {
//				this.alertTranslations.unread = value;
//			}
//		)
//		this.translateService.get('Notify').subscribe(
//			value => {
//				this.alertTranslations.notify = value;
//			}
//		)
//
//		this.alertMore = this.alertCtrl.create({
//			title: this.alertTranslations.alertMoreTitle,
//			inputs: [
//				{
//					type: 'radio',
//					label: this.alertTranslations.forward,
//					value: 'forward'
//				},
//				{
//					type: 'radio',
//					label: this.alertTranslations.forwardwithaction,
//					value: 'forwardwithaction'
//				},
//				{
//					type: 'radio',
//					label: this.alertTranslations.mark,
//					value: 'mark'
//				},
//				{
//					type: 'radio',
//					label: this.alertTranslations.unread,
//					value: 'unread'
//				},
//			],
//			buttons: [
//				{
//					text: translation.cancel
//				},
//				{
//					text: translation.ok.toUpperCase(),
//					handler: (data: any) => {
//						console.log('Radio data:', data);
//
//						switch (data) {
//							case 'forward':
//								this.navCtrl.push(ActionPage, {
//									items: this.items,
//									index: index,
//									idcodigo: 71,
//								});
//								break;
//							case 'forwardwithaction':
//								this.navCtrl.push(ActionPage, {
//									items: this.items,
//									index: index,
//									idcodigo: 73,
//								});
//								break;
//							case 'unread':
//								this.unread(item, slidingItem);
//								break;
//							case 'mark':
//								this.mark(item, slidingItem);
//								break;
//						}
//					}
//				}
//			]
//		});
//		this.alertMore.present();
//		slidingItem.close();
//	}


	arquive(item, slidingItem: IonItemSliding) {
		//console.log('arquive ' + item);
		slidingItem.close();
		for (let i = 0; i < this.items.length; i++) {
			if (this.items[i] == item) {
				this.items.splice(i, 1);
			}
		}
	}

	selectedClean(): void {
		this.countItemsChecked = 0;
		this.itemsIdsSelected = [];

		for (let i = 0; i < this.items.length; i++) {
			this.items[i].checked = false;
		}
	}

	selected(items: any, index: number): void {

		this.index = index;
		items[index].checked = !items[index].checked;

		//console.log(items[index]);

		this.countItemsChecked = 0;

		const indexOfIddoc = this.itemsIdsSelected.indexOf(items[index].docid);
		if (true === items[index].checked) {
			if (-1 === indexOfIddoc) {
				this.itemsIdsSelected.push(items[index].docid);
			}
		} else {
			this.itemsIdsSelected.splice(indexOfIddoc, 1);
		}

		for (let i = 0; i < this.items.length; i++) {
			if (this.items[i].checked == true) {
				this.countItemsChecked++;
			}
		}

		//console.log('this.countItemsChecked', this.countItemsChecked);
	}

//	selectedMore(): void {
//		let translation: any = {cancel: '', ok: '', more: '', associatewitheachother: '', linkdocuments: ''};
//		this.translateService.get('More').subscribe(
//			value => {
//			translation.more = value;
//		});
//		this.translateService.get('Forward').subscribe(
//			value => {
//				translation.forward = value;
//			}
//		)
//		this.translateService.get('Forward with pending action').subscribe(
//			value => {
//				translation.forwardwithaction = value;
//			}
//		)
//		this.translateService.get('Associate with each other').subscribe(
//			value => {
//			translation.associatewitheachother = value;
//		});
//		this.translateService.get('Link').subscribe(
//			value => {
//			translation.linkdocuments = value;
//		});
//		this.translateService.get('Cancel').subscribe(
//			value => {
//				translation.cancel = value;
//			}
//		)
//		this.translateService.get('ok').subscribe(
//			value => {
//				translation.ok = value;
//			}
//		)
//		let alertMore = this.alertCtrl.create({
//			title: translation.more,
//			inputs: [
//				/*{
//					type: 'radio',
//					label: translation.forward,
//					value: 'forward'
//				},
//				{
//					type: 'radio',
//					label: translation.forwardwithaction,
//					value: 'forwardwithaction'
//				},*/
//				{
//					type: 'radio',
//					label: translation.associatewitheachother,
//					value: 'associatewitheachother'
//				},
//				{
//					type: 'radio',
//					label: translation.linkdocuments,
//					value: 'linkdocuments'
//				}
//			],
//			buttons: [
//				{
//					text: translation.cancel
//				},
//				{
//					text: translation.ok.toUpperCase(),
//					handler: (data: any) => {
//						console.log('Radio data:', data);
//						let items: Array<any> = [];
//						if ('forward' == data || 'forwardwithaction' == data) {
//							for (let i = 0; i < this.itemsIdsSelected.length; i++) {
//								for (let j = 0; j < this.items.length; j++) {
//									if (this.itemsIdsSelected[i] == this.items[j].iddoc) {
//										items.push(this.items[j]);
//										console.log(items);
//										break;
//									}
//								}
//							}
//						} else if ('linkdocuments' == data){
//
//							console.log('this.itemsIdsSelected', this.itemsIdsSelected)
//						}
//
//						switch (data) {
//							case 'forward':
//								this.navCtrl.push(ActionPage, {
//									items: items,
//									idcodigo: 71,
//									pageaction: 'actionFind'
//								});
//								break;
//							case 'forwardwithaction':
//								this.navCtrl.push(ActionPage, {
//									items: items,
//									idcodigo: 73,
//									pageaction: 'actionFind'
//								});
//								break;
//							case 'associatewitheachother':
//								this.associate();
//								break;
//							case 'linkdocuments':
//
//								if (this.configService.getVar('link_documents')) {
//									let link_documents_vars = this.configService.getVar('link_documents');
//									link_documents_vars = Object.assign(link_documents_vars, {countPagesToRemove: 0});
//									this.configService.setVar('link_documents', link_documents_vars);
//								} else {
//									this.configService.setVar('link_documents', {countPagesToRemove: 0});
//								}
//
//								this.navCtrl.push(LinkDocumentsPage, {
//									itemsIds: this.itemsIdsSelected
//								});
//								break;
//						}
//					}
//				}
//			]
//		});
//		alertMore.present();
//	}

	doRefresh(refresher: any): void {

		this.refresherIsOn = true;
		this.refresher = refresher;

		this.find();
	}

	doInfinite(infiniteScroll: any): void {

		const datainiciodesdebackup = this.searchParams.datainiciodesde;
		const datainicioatebackup = this.searchParams.datainicioate;

		const datalimitedesdebackup = this.searchParams.datalimitedesde;
		const datalimiteatebackup = this.searchParams.datalimiteate;

		this.searchParams.limit = this.searchParams.limit + 20;
		this.actionService.find(this.searchParams).subscribe({
			next: (data: any) => {
				this.items = [];
				this.itemstypes = [];
				this.groups = [];
				this.processResults(data);

				if (this.totalfilter < this.searchParams.limit - 20) {
					this.searchParams.limit = this.searchParams.limit - 20;
				}

				if (this.searchParams.limit > this.totalfilter) {
					this.searchParams.limit = this.totalfilter;
				}
			},
			error: err => {
			},
			complete: () => {
				infiniteScroll.target.complete();
			}
		});

		this.searchParams.datainiciodesde = datainiciodesdebackup;
		this.searchParams.datainicioate = datainicioatebackup;

		this.searchParams.datalimitedesde = datalimitedesdebackup;
		this.searchParams.datalimiteate = datalimiteatebackup;
	}

	onTypeChange(selectedValue: any): void {
		//reset count items
		this.countItemsChecked = 0;
		this.searchParams.idaction = selectedValue;
		//console.log('selected value ' + selectedValue);
		this.find();

		//console.log('this.items');
		//console.log(this.items);
	}

	onGroupChange(selectedValue: any): void {
	}

	onPriorityChange(selectedValue: any): void {
	}

	edit(): void {

		this.editEnable = !this.editEnable;
		this.selectedClean();
	}

	editOff(items: any): void {
		this.editEnable = false;
		this.countItemsChecked = 0;

		let sizeOfItems = 0;
		let key;
		for (key in items) {
			if (items.hasOwnProperty(key)) {
				sizeOfItems++;
			}
		}

		//console.log('Tamanho do array ' + sizeOfItems);

		for (let i = 0; i < sizeOfItems; i++) {
			if (items[i].checked == true) {
				items[i].checked = false;
			}
		}
	}

	goToActionForExecute(): void {

		let actionsSelectedToExecute = [];

		if (this.countItemsChecked == 1) { //simple action access

			let index = 0;

			for (let i = 0; i < this.items.length; i++) {
				if (this.items[i].checked) {
					index = i;
					break;
				}
			}
			this.events.publish('doc:pushPage', {
				page: 'ActionPage',
				params: {
					actions: this.items,
					index: index,
					frompage: 'ListActionsPage'
				}
			});

		} else { //massive action execute

			let idaccao = 0;
			let executeEnable = true;

			// CHECK THE FIRST IDACCAO OF THE ACTIONS SELECTED AND STORE IT
			for (const a of this.items) {
				if (a.checked) {
					idaccao = a.idaccao;
					break;
				}
			}

			// THEN COMPARE IT TO ALL THE ACTIONS SELECTED, IF JUST ONE IS DIFFERENT DO NOT ALLOW EXECUTE

			for (const a of this.items) {
				if (a.checked) {

					if ((a.idaccao / idaccao) == 1) {
						actionsSelectedToExecute.push(a);

					} else {

						this.toast.show({
							message: 'The actions selected must be the same type to execute',
							duration: 3000,
							cssClass: 'warning'
						});

						actionsSelectedToExecute = [];
						executeEnable = false;
						break;
					}

				}
			}

			//console.log('idaccao', idaccao);
			//console.log('actionsSelectedToExecute', actionsSelectedToExecute);

			if (executeEnable) {
				this.events.publish('doc:pushPage', {
					page: 'ActionPage',
					params: {
						actions: actionsSelectedToExecute,
						frompage: 'ListActionsPage'
					}
				});

			}
		}


	}

//	forward(items: any): void {
//
//		this.translateService.get('Forward').subscribe(
//			value => {
//				this.alertTranslations.alertForwardTitle = value;
//			}
//		)
//		this.translateService.get('Document').subscribe(
//			value => {
//				this.alertTranslations.document = value;
//			}
//		)
//		this.translateService.get('Pending action').subscribe(
//			value => {
//				this.alertTranslations.pendingAction = value;
//			}
//		)
//		this.translateService.get('Cancel').subscribe(
//			value => {
//				this.alertTranslations.cancel = value;
//			}
//		)
//
//		this.forwardAlert = this.alertCtrl.create();
//		this.forwardAlert.setTitle(this.alertTranslations.alertForwardTitle);
//		this.forwardAlert.addInput({
//			type: 'radio',
//			label: this.alertTranslations.document,
//			value: 71,
//			checked: true
//		});
//		this.forwardAlert.addInput({
//			type: 'radio',
//			label: this.alertTranslations.pendingAction,
//			value: 73,
//			checked: false
//		});
//
//		this.forwardAlert.addButton(this.alertTranslations.cancel);
//		this.forwardAlert.addButton({
//			text: this.alertTranslations.ok.toUpperCase(),
//			handler: data => {
//
//				if (this.countItemsChecked == 1) {
//
//					this.navCtrl.push(ActionPage, {
//						items: items,
//						index: this.index,
//						idcodigo: data,
//						singleAction: true
//					});
//
//				this.editEnable = false;
//				} else {
//					let actionsToForward = [];
//					for (let a of items) {
//						if (a.checked) {
//								actionsToForward.push(a);
//						}
//					}
//					this.navCtrl.push(ActionPage, {
//						items: actionsToForward,
//						idcodigo: data
//					});
//					this.editEnable = false;
//				}
//
//			}
//		});
//		this.forwardAlert.present();
//	}

	goToAction(actions: any, index: number): void {

		if (!this.editEnable){
			this.iconLoading= true;
			this.actionAccessedIndex = index;

			actions[index].action_was_accessed = true;

			this.events.publish('doc:pushPage', {
				page: 'ActionPage',
				params: {
					actions: actions,
					index: index,
					frompage: 'ListActionsPage'
				}
			});
		}
	}

	goToSchedule(action: any, slidingItem?: IonItemSliding) {

		this.events.publish('doc:pushPage', {
			page: 'ActionSchedulePage',
			params: {
				action: action,
				frompage: 'ListActionsPage'
			}
		});

		if (slidingItem != undefined ) {
			slidingItem.close();
		}
	}

	goToInfo(action: any, slidingItem?: IonItemSliding) {

		this.events.publish('doc:pushPage', {
			page: 'InfoPage',
			params: {
				document: action.document,
				frompage: 'ListActionsPage'
			}
		});

		if (slidingItem != undefined ) {
			slidingItem.close();
		}

	}

	goToChronologicalScheme(action: any, slidingItem?: IonItemSliding, ctype?: any): void {

		this.events.publish('doc:pushPage', {
			page: 'ChronologicalschemePage',
			params: {
				document: action.document,
				type: ctype,
				frompage: 'ListActionsPage'
			}
		});

		if (slidingItem != undefined ) {
			slidingItem.close();
		}

	}

	showAdvancedSearch(): void {
		this.fShowHideAdvancedSearch = true;
	}

	hideAdvancedSearch(): void {
		const bar = document.getElementsByTagName('ion-searchbar');
		bar[0].value = '';
		this.fShowHideAdvancedSearch = false;
	}



	associate(): void {

		let idsToAssociate = [];

		if (this.itemsIdsSelected.length > 1) {
			idsToAssociate = this.itemsIdsSelected;
		}

		this.loading.show({});

		this.documentService.associate({iddocs: idsToAssociate}).subscribe(
					data => {

						for (let i = 0; i < this.items.length; i++) {
							if (this.itemsIdsSelected.indexOf(this.items[i].docid) > -1) {
								this.items[i].document.associateddocuments.push({});
							}
						}

						this.loading.dismiss();
						this.edit();
						this.fab.close();

						this.toast.show({message: 'The documents selected were associated with each other succesfully', duration: 3000, cssClass: 'success'});
					},
					err => {
						this.loading.dismiss();
						this.toast.show({message: 'Error associating documents with each other', duration: 3000, cssClass: 'error'});
					},
					() => {
					}
				);

	}

	buttonSearchDisabled(){

		// if(		this.searchParams.searchString
		// 	|| 	(this.searchActionsByStartDate && this.searchActionsByStartDateSince && this.searchActionsByStartDateUpTo)
		// 	|| 	(this.searchActionsByLimitDate && this.searchActionsByLimitDateSince && this.searchActionsByLimitDateUpTo)
		// 	|| 	this.searchParams.priorityflag
		// 	||	this.searchParams.showScheculed
		// 	||	this.searchParams.showViponly
		// 	||	this.searchParams.action_was_accessed
		// 	) {
        //
		// 	return false
		// } else {
		// 	return true
		// }

	}


	async goBack(){
		const state = await this.nav.getActive();
		const view = await this.nav.getByIndex(1);
		if (view === undefined) {
			this.events.publish('doc:setRootPage', {
				page: 'HomePage',
				params: {
					actions: undefined,
					frompage: 'ListActionsPage'
				}
			});
		}
	}

	goToHome(){
		this.events.publish('doc:setRootPage', {
			page: 'HomePage',
			params: {
				actions: undefined,
				frompage: 'ListActionsPage'
			}
		});
	}

	goToListActions(){
		this.nav.getActive().then((view) => {
			this.events.publish('doc:pushPage', {
				page: 'ListActionsPage',
				params: {
					actions: undefined,
					frompage: 'ListActionsPage'
				}
			});
			timer(50).forEach(() => {
				this.nav.removeIndex(view.nav.views.length - 2);
			});
		});
	}

	goToListDocuments(){
		this.nav.getActive().then((view) => {
			this.events.publish('doc:pushPage', {
				page: 'ListDocumentsPage',
				params: {
					actions: undefined,
					frompage: 'ListActionsPage'
				}
			});
			timer(50).forEach(() => {
				this.nav.removeIndex(view.nav.views.length - 2);
			});
		});
	}

	goToListEmails(){
		this.nav.getActive().then((view) => {
			this.events.publish('doc:pushPage', {
				page: 'ListEmailsPage',
				params: {
					actions: undefined,
					frompage: 'ListActionsPage'
				}
			});
			timer(50).forEach(() => {
				this.nav.removeIndex(view.nav.views.length - 2);
			});
		});
	}

	goToMenu(){
		this.events.publish('doc:pushPage', {
			page: 'MenuPage',
			params: {
				actions: undefined,
				frompage: 'ListActionsPage'
			}
		});
	}

	openActionSheetFor(option: string, params?: any, slidingItem?: IonItemSliding){

		//console.log(option, params);


		if ('more' === option){

			if (undefined != params) {

				if ('number' === typeof params.idaccaorev) {
					this.actionSheet.show('ListActionsPage', 'more', {
						item: params,
					});

					if (slidingItem != undefined) {
						slidingItem.close();
					}

				} else {
					//console.log('Error: openActionSheetFor more expected params as action')
				}

			} else {
				//console.log('Error: openActionSheetFor more params not defined')
			}



		} else if (option === 'forwardchoice') {

			//console.log('forwardchoice');


			// get actions checked
			const actionsChecked = [];
			for (let item of this.items) {
				if (item.checked) {
					actionsChecked.push(item);
				}
			}

			//console.log(actionsChecked.length);

			if (actionsChecked.length == 1) {

				this.actionSheet.show('ListActionsPage', 'forwardchoice', {
					item: actionsChecked // one action
				})

			} else {

				this.actionSheet.show('ListActionsPage', 'forwardchoice', {
					items: actionsChecked
				})

			}

		} else if (option === 'selectedmore') {

			// get actions checked
			const actionsChecked = [];
			for (const item of this.items) {
				if (item.checked) {
					actionsChecked.push(item);
				}
			}

			this.actionSheet.show('ListActionsPage', 'selectedmore', {
				items: actionsChecked
			});

		}
	}

	ngOnDestroy(): void {
		if (this.actionServiceSubscription) {
			this.actionServiceSubscription.unsubscribe();
		}
	}

}
