import { Component, OnInit } from '@angular/core';

import { IonNav, NavController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { StorageUtils } from '../../utils/storage-utils';
import { StringUtils } from '../../utils/string-utils';
import { ConfigService } from '../../services/config-service';
import { UserService } from '../../services/user-service';
import { Loading } from '../../controllers/message-controller';
import { HomePage } from '../home/home';
import { ListActionsPage } from '../list-actions/list-actions';
import { AccountService } from '../../services/account-service';
import { WebStorageService } from '../../services/webstorage-service';
import { Router } from '@angular/router';

@Component({
	templateUrl: 'login.html',
	providers: [UserService, Loading],
	styleUrls: ['login.scss']
})

export class LoginPage implements OnInit {
	haserror = 0;
	credentials = {username: '', password: '', server_url: '', name: '', id: 0};
	user: any;
	usr: any;
	//pos: any;
	img: boolean;
	fEditServer = true;
	platform_cordova = '0';

	constructor(
				public navCtrl: NavController,
				public navParams: NavParams,
				public configService: ConfigService,
				public userService: UserService,
				public translate: TranslateService,
				public loading: Loading,
				public localStorageService: WebStorageService,
				public nav: IonNav,
				private _accountService: AccountService,
				private router: Router
			) {

		this.user = this.userService.getUser();
		if (typeof this.user.id !== 'undefined') {
			//this.pos = this.user.email.search('@');
			//this.user.email = this.user.email.slice(0,this.pos);
			this.credentials.username = this.user.username;
		}

		this.platform_cordova = StorageUtils.getItem('platform_cordova');

		if ('1' === StorageUtils.getItem('platform_cordova')) {
			this.credentials.server_url = StorageUtils.getItem('server_url');
		} else {
			this.credentials.server_url = this.configService.getServerurl();
		}

		if (null !== this.credentials.server_url && '' !== this.credentials.server_url) {
			this.fEditServer = false;
		}

		if (this.navParams.get('changeServer')){
			this.fEditServer = true;
		}
	}

    public login() {
		this.credentials.server_url = this.credentials.server_url.replace(/\/{1,}$/gi, '');
		this.credentials.server_url = this.credentials.server_url.trim();
		this.credentials.username	= this.credentials.username.toLowerCase();

		const credentials        = JSON.parse(JSON.stringify(this.credentials));
		credentials.server_url = this.credentials.server_url;
		credentials.username   = this.credentials.username;
		credentials.password   = StringUtils.base64_encode(credentials.password);

		StorageUtils.setItem('server_url', this.credentials.server_url);
		this.localStorageService.set('server_url', this.credentials.server_url);

		let usr = this.userService.getUser();
		usr = Object.assign(usr, {email: this.credentials.username});
		this.userService.setUser(JSON.stringify(usr));

		this.loading.show({});
		this.userService.login(credentials).subscribe({
			next: (res: any) => {
				try {
					StorageUtils.setItem('access_token', res.access_token);
					this.localStorageService.set('access_token', res.access_token);
					this.localStorageService.set('refresh_token', res.refresh_token);
				} catch(e) {
					//console.log(e); // error in the above json
				}
				try {
					usr = res;
					this.usr = res;
				} catch(e) {
					//console.log(e); // error in the above json
				}

				if (this.usr){
					
					this.usr = Object.assign(this.usr, {username: this.credentials.username});
					this.userService.setUser(JSON.stringify(usr));

					// get user language
					if(usr.lang){
						this.userService.defaultlang = 	usr.lang;
					}	
					let lang: string = this.userService.defaultlang;

					const user = this.userService.getUser();

					if ('undefined' == typeof user.defaultpage) {
						user.defaultpage = 'HomePage';
					}

					if ('undefined' != typeof user.id) {
						lang = user.lang;

						if ('1' == user.avatar) {
							this.userService.avatar = this.configService.getServerurl() + '/images/avatar/' + user.id + '.jpg';
						} else {
							this.userService.avatar = this.configService.getServerurl() + '/assets/images/avatar/avatar.gif';
						}
					}
					this.localStorageService.set('id', usr.id);
					this.localStorageService.set('username', usr.username);
					this.localStorageService.set('name', usr.name);

					//set app language
					this.translate.setDefaultLang(lang);
					this.translate.use(lang);

					let rootPage: any;
					switch (user.defaultpage) {
						case 'ListActionsPage':
							rootPage = ListActionsPage;
							break;
						case 'Home':
							rootPage = HomePage;
							break;
						default:
							rootPage = HomePage;
					}
					//redifine root page
					this.nav.setRoot(rootPage);

					// Passar para no final do find();
					//this.nav.push(ListActionsPage);

					this.userService.i18n({lang:lang}).subscribe(
						data => {
							if(data) {
								try {
									this.translate.setTranslation(lang, data, true);

								} catch(e) {
										//console.log(e);
								}
								};
						},
						err => {
						},
						() => {
						}
					);

					this._accountService.login();

						try {
							this.loading.dismiss();
						} catch(e) {
							//console.log(e); // error in the above json
						}


				}


			},
            error: (err) => {
				//console.log(err);
				for (const res in err) {
					let item = err[res];
					this.credentials['error_' + item.field] = item.message;
				}

				if (3 == err.type) { //ERR_TUNNEL_CONNECTION_FAILED
					this.credentials['error_password'] = 'Server unreachable';
				}

				if (err.type === undefined) {
					this.credentials['error_password'] = err.error[0].message;
				}

				this.haserror = 1;

				try {
					this.loading.dismiss();
				} catch(e) {
					//console.log(e); // error in the above json
				}
            },
			complete: () => {
				try {
				this.loading.dismiss();
				} catch(e) {
					//console.log(e); // error in the above json
				}

			}
		});
    }

	public loginError(): boolean {
		if (0 == this.haserror) {
			return false;
		} else {
			return true;
		}
	}

	public loginCleanError(): void {
		this.haserror = 0;
	}

	userInfo(): boolean {
		let user = this.userService.getUser();
		if (typeof user.id !== 'undefined') {
			if (user.avatar == 0) {
				this.user.avatar = this.userService.avatar = this.configService.getServerurl() + '/assets/images/avatar/avatar.gif';
				this.img = false;
			} else {
				this.user.avatar = this.userService.avatar = this.configService.getServerurl() + '/images/avatar/' + user.id + '.jpg';
				this.img = true;
			}

			return true;
		} else {
			return false;
		}
	}

	resetUser(): void {
		this.userService.removeUser();
		this.credentials.username = '';
		this.credentials.name = '';
		this.credentials.id = 0;
	}

	showHideEditServer(): void {
		this.fEditServer = !this.fEditServer;
	}

	editServer(): boolean {
		return this.fEditServer;
	}

	focusServer(): void {
		if (null === this.credentials.server_url || '' === this.credentials.server_url) {
			this.credentials.server_url = 'https://';
		}
	}

	lowerUrl(url: string): void {
		this.credentials.server_url = url.toLowerCase();
	}
	ngOnInit(): void {
		const test = 1;
	}

	goToContacts(){
		window.open('https://www.iportaldoc.com');
	}
/*
	ngOnInit(): void {
		if(this.userService.isAuthenticated() === true){
			this.router.navigate(['home']);
		}
	}*/
}
